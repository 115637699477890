import { IdOrUndefined, RecordId } from "../interfaces/id"
import { apiEndpoint, endpoint } from "../utils/api"

export const BASE_API_URL = import.meta.env.VITE_APP_PUBLIC_URL || 'http://localhost:3001'
export const BASE_APP_URL = import.meta.env.VITE_APP_FRONT_URL || 'http://localhost:5173'

export const LOCALSTORAGE_REDIRECT_KEY = "compass-redirect"

export const ROUTES = {
  auth: {
    login: endpoint('/login'),
    signup: endpoint('/signup'),
    password: endpoint('/password'),
    createFromEmail: apiEndpoint('/users/reset'),
    sendMagicLink: apiEndpoint('/users/magic'),
    loginWithMagicLink: (token: string) => endpoint(`/users/auth_token/${token}`),
    me: apiEndpoint('/me'),
    search_token: apiEndpoint('/me/search_token'),
  },
  metadata: {
    get: () => apiEndpoint(`/metadata`),
  },
  users: {
    get: (userId: RecordId) => apiEndpoint(`/users/${userId}`),
    update: (userId: RecordId) => apiEndpoint(`/users/${userId}`),
    create_from_email: () => apiEndpoint(`/users/create_from_email`),
    list: (organizationId: RecordId) => apiEndpoint(`/organizations/${organizationId}/users`),
    listForStructure: (structureId: RecordId) => apiEndpoint(`/structures/${structureId}/users`),
    getInvitation: (token: string) => apiEndpoint(`/invitations?invitation_token=${token}`),
    acceptInvitation: (token: string) => apiEndpoint(`/invitations?invitation_token=${token}`),
    mission_ids: () => apiEndpoint('/me/mission_ids'),
  },
  organizations: {
    list: apiEndpoint('/organizations'),
    update: (organizationId: RecordId) => apiEndpoint(`/organizations/${organizationId}`),
    search: (organizationId: RecordId) => apiEndpoint(`/organizations/${organizationId}/search`),
  },
  organizationsUsers: {
    list: (organizationId: RecordId) => apiEndpoint(`/organizations/${organizationId}/organizations_users`),
    // create: (organizationId: RecordId) => apiEndpoint(`/organizations/${organizationId}/organizations_users`),
    invite: (organizationId: RecordId) => apiEndpoint(`/organizations/${organizationId}/organizations_users/invite`),
    delete: (organizationsUserId: RecordId) => apiEndpoint(`/organizations_users/${organizationsUserId}`),
    update: (organizationsUserId: RecordId) => apiEndpoint(`/organizations_users/${organizationsUserId}`),
  },
  structures: {
    list: (organizationId: RecordId) => apiEndpoint(`/organizations/${organizationId}/structures`),
    create: (organizationId: RecordId) => apiEndpoint(`/organizations/${organizationId}/structures`),
    get: (structureId: RecordId) => apiEndpoint(`/structures/${structureId}`),
    delete: (structureId: RecordId) => apiEndpoint(`/structures/${structureId}`),
    update: (structureId: RecordId) => apiEndpoint(`/structures/${structureId}`),
    search: (structureId: RecordId) => apiEndpoint(`/structures/${structureId}/search`),
    equipmentsList: (structureId: RecordId) => apiEndpoint(`/structures/${structureId}/equipments`),
    equipmentHistory: (structureId: RecordId) => apiEndpoint(`/structures/${structureId}/equipment_histories`),
  },
  userStructures: {
    listForStructure: (structureId: RecordId) => apiEndpoint(`/structures/${structureId}/user_structures`),
    listForUser: (userId: RecordId) => apiEndpoint(`/users/${userId}/user_structures`),
    createForStructure: (structureId: RecordId) => apiEndpoint(`/structures/${structureId}/user_structures`),
    get: (userStructureId: RecordId) => apiEndpoint(`/user_structures/${userStructureId}`),
    delete: (userStructureId: RecordId) => apiEndpoint(`/user_structures/${userStructureId}`),
    update: (userStructureId: RecordId) => apiEndpoint(`/user_structures/${userStructureId}`),
  },
  missions: {
    list: (organizationId: RecordId) => apiEndpoint(`/organizations/${organizationId}/missions`),
    create: (organizationId: RecordId) => apiEndpoint(`/organizations/${organizationId}/missions`),
    get: (missionId: RecordId) => apiEndpoint(`/missions/${missionId}`),
    delete: (missionId: RecordId) => apiEndpoint(`/missions/${missionId}`),
    update: (missionId: RecordId) => apiEndpoint(`/missions/${missionId}`),
    close: (missionId: RecordId) => apiEndpoint(`/missions/${missionId}/close`),
  },
  friendships: {
    list: () => apiEndpoint("/friendships"),
    create: () => apiEndpoint("/friendships"),
    get: (friendshipId: RecordId) => apiEndpoint(`/friendships/${friendshipId}`),
    delete: (friendshipId: RecordId) => apiEndpoint(`/friendships/${friendshipId}`),
  },
}

export const organizationsRoute = (organizationId: IdOrUndefined) => `/orgs/${organizationId}`
export const structuresRoute = (organizationId: IdOrUndefined) => `${organizationsRoute(organizationId)}/structures`
export const structureRoute = (organizationId: IdOrUndefined, structureId: IdOrUndefined) => `${structuresRoute(organizationId)}/${structureId}`
export const structureSummaryRoute = (organizationId: IdOrUndefined, structureId: IdOrUndefined) => `${structureRoute(organizationId, structureId)}/summary`
export const planningForStructureRoute = (organizationId: IdOrUndefined, structureId: IdOrUndefined) => `${structureRoute(organizationId, structureId)}/reservations`

export const equipmentsRoute = (organizationId: IdOrUndefined) => `${organizationsRoute(organizationId)}/equipments`
export const equipmentRoute = (organizationId: IdOrUndefined, equipmentId: IdOrUndefined) => `${equipmentsRoute(organizationId)}/${equipmentId}`
export const equipmentsForStructureRoute = (organizationId: IdOrUndefined, structureId: IdOrUndefined) => `${structureRoute(organizationId, structureId)}/equipments`
export const equipmentsNewRoute = (organizationId: IdOrUndefined) => `${equipmentsRoute(organizationId)}/new`
export const equipmentEditRoute = (organizationId: IdOrUndefined, equipmentId: IdOrUndefined) => `${equipmentRoute(organizationId, equipmentId)}/edit`
export const equipmentSummaryRoute = (organizationId: IdOrUndefined, equipmentId: IdOrUndefined) => `${equipmentRoute(organizationId, equipmentId)}/summary`
export const equipmentCalendarRoute = (organizationId: IdOrUndefined, equipmentId: IdOrUndefined) => `${equipmentRoute(organizationId, equipmentId)}/calendar`

export const vehiculesRoute = (organizationId: IdOrUndefined) => `${organizationsRoute(organizationId)}/vehicules`
export const vehiculeRoute = (organizationId: IdOrUndefined, vehiculeId: IdOrUndefined) => `${vehiculesRoute(organizationId)}/${vehiculeId}`
export const vehiculeSummaryRoute = (organizationId: IdOrUndefined, vehiculeId: IdOrUndefined) => `${vehiculeRoute(organizationId, vehiculeId)}/summary`
export const vehiculeCalendarRoute = (organizationId: IdOrUndefined, equipmentId: IdOrUndefined) => `${vehiculeRoute(organizationId, equipmentId)}/calendar`
export const vehiculeEditRoute = (organizationId: IdOrUndefined, equipmentId: IdOrUndefined) => `${vehiculeRoute(organizationId, equipmentId)}/edit`

export const reservationsRoute = (organizationId: IdOrUndefined) => `${organizationsRoute(organizationId)}/reservations`
export const reservationsSearchRoute = (organizationId: IdOrUndefined) => `${organizationsRoute(organizationId)}/search`
export const reservationsForStructureRoute = (organizationId: IdOrUndefined, structureId: IdOrUndefined) => `${structureRoute(organizationId, structureId)}/reservations`


export const missionsRoute = () => `/missions`
export const missionsForStructureRoute = (organizationId: IdOrUndefined, structureId: IdOrUndefined) => `${structureRoute(organizationId, structureId)}/missions`
export const missionsNewRoute = (organizationId: IdOrUndefined) => `${missionsRoute()}/new`
export const missionRoute = (organizationId: IdOrUndefined, missionId: IdOrUndefined) => `${missionsRoute()}/${missionId}`
export const missionEditRoute = (organizationId: IdOrUndefined, missionId: IdOrUndefined) => `${missionRoute(organizationId, missionId)}/edit`
export const missionSummaryRoute = (organizationId: IdOrUndefined, missionId: IdOrUndefined) => `${missionRoute(organizationId, missionId)}/summary`
export const missionEquipmentsRoute = (organizationId: IdOrUndefined, missionId: IdOrUndefined) => `${missionRoute(organizationId, missionId)}/equipments`

export const missionEquipmentRequirementsForMissionRoute = (organizationId: IdOrUndefined, missionId: IdOrUndefined) => `${missionRoute(organizationId, missionId)}/requirements`

export const accountRoute = (organizationId: IdOrUndefined) => `${organizationsRoute(organizationId)}/account`
export const accountEditRoute = (organizationId: IdOrUndefined) => `${accountRoute(organizationId)}/edit`
export const settingsOrganizationRoute = (organizationId: IdOrUndefined) => `${organizationsRoute(organizationId)}/settings`
export const generalSettingsOrganizationRoute = (organizationId: IdOrUndefined) => `${settingsOrganizationRoute(organizationId)}/general`
export const usersSettingsOrganizationRoute = (organizationId: IdOrUndefined) => `${settingsOrganizationRoute(organizationId)}/users`


export const equipmentTypesRoute = (organizationId: IdOrUndefined) => `${settingsOrganizationRoute(organizationId)}/equipmentTypes`
export const equipmentTypeRoute = (organizationId: IdOrUndefined, equipmentTypeId: IdOrUndefined) => `${equipmentTypesRoute(organizationId)}/${equipmentTypeId}`
export const equipmentTypesForStructureRoute = (organizationId: IdOrUndefined, structureId: IdOrUndefined) => `${structureRoute(organizationId, structureId)}/equipmentTypes`
export const equipmentTypesNewRoute = (organizationId: IdOrUndefined) => `${equipmentTypesRoute(organizationId)}/new`
export const equipmentTypeEditRoute = (organizationId: IdOrUndefined, equipmentTypeId: IdOrUndefined) => `${equipmentTypeRoute(organizationId, equipmentTypeId)}/edit`
export const equipmentTypeSummaryRoute = (organizationId: IdOrUndefined, equipmentTypeId: IdOrUndefined) => `${equipmentTypeRoute(organizationId, equipmentTypeId)}/summary`

export const ridesRoute = (organizationId: IdOrUndefined) => `${organizationsRoute(organizationId)}/rides`
export const ridesForStructureRoute = (organizationId: IdOrUndefined, structureId: IdOrUndefined) => `${structureRoute(organizationId, structureId)}/rides`
export const ridesNewRoute = (organizationId: IdOrUndefined) => `${ridesRoute(organizationId)}/new`
export const rideRoute = (organizationId: IdOrUndefined, rideId: IdOrUndefined) => `${ridesRoute(organizationId)}/${rideId}`
export const rideEditRoute = (organizationId: IdOrUndefined, rideId: IdOrUndefined) => `${rideRoute(organizationId, rideId)}/edit`
export const rideSummaryRoute = (organizationId: IdOrUndefined, rideId: IdOrUndefined) => `${rideRoute(organizationId, rideId)}/summary`

export const usersRoute = (organizationId: IdOrUndefined) => `${organizationsRoute(organizationId)}/users`
export const usersForStructureRoute = (organizationId: IdOrUndefined, structureId: IdOrUndefined) => `${structureRoute(organizationId, structureId)}/users`
export const usersNewRoute = (organizationId: IdOrUndefined) => `${usersRoute(organizationId)}/new`
export const userRoute = (organizationId: IdOrUndefined, userId: IdOrUndefined) => `${usersRoute(organizationId)}/${userId}`
export const userEditRoute = (organizationId: IdOrUndefined, userId: IdOrUndefined) => `${userRoute(organizationId, userId)}/edit`
export const userSummaryRoute = (organizationId: IdOrUndefined, userId: IdOrUndefined) => `${userRoute(organizationId, userId)}/summary`

export const goEquipmentRoute = (organizationId: IdOrUndefined, equipmentId: IdOrUndefined) => `/go/o/${organizationId}/e/${equipmentId}`
export const goEquipmentSetupMissionRoute = (organizationId: IdOrUndefined, equipmentId: IdOrUndefined, missionId: IdOrUndefined) => `${goEquipmentRoute(organizationId, equipmentId)}/m/${missionId}/start`
export const goEquipmentFinishRideRoute = (organizationId: IdOrUndefined, equipmentId: IdOrUndefined, missionId: IdOrUndefined, rideId: IdOrUndefined) => `${goEquipmentRoute(organizationId, equipmentId)}/m/${missionId}/r/${rideId}/finish`

// @TODO
export const ROUTE_TO_START_TODO = (organizationId: IdOrUndefined, structureId: IdOrUndefined) => `${structureRoute(organizationId, structureId)}/equipmentTypes`


export const routeForPolymorphicLocation = (organizationId: IdOrUndefined, locationId: IdOrUndefined, locationType: string) => {
  switch (locationType) {
    case 'Structure':
      return structureRoute(organizationId, locationId)
  
    default:
    return ""
  }
}