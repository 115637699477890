// import "instantsearch.css/themes/algolia-min.css";

import { useLoaderData } from 'react-router-dom';
import { Badge, Box, HStack, Stack, Tag, Text, VStack } from '@chakra-ui/react';

import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import {
  InstantSearch,
  Highlight} from "react-instantsearch";
import { Hits } from '@/components/Hits/Hits';
import { HitsPagination } from '@/components/HitsPagination/HitsPagination';
import { MissionsListToolbar } from './MissionsListToolbar';
import { MissionsListFilters } from './MissionsListFilters';
import { formatDateShort, formatDistanceShort } from '@/utils/datesFormat';
import { isPast, parseISO } from 'date-fns';
import { poppinsFont, truncate } from '@/utils/string';
import { TAGS_LABELS } from '@/utils/tags';
import { getUserMissionIds } from '@/api/user';
import { useCompassQuery } from '@/hooks/query';
import { CategoryNav } from './CategoryNav';
import { TbMailCheck } from 'react-icons/tb';
import { RecordId } from '@/interfaces/id';
import { useUser } from '@/hooks/auth';
import type { ApiResource } from '@/utils/api';
import type { FriendshipAttributes, FriendshipAttributesMeta } from '@/interfaces/friendship';

export interface MissionListProps {
  onMissionClick: (missionId: number | undefined) => void
  selectedMissionId: RecordId | undefined
  friends: ApiResource<FriendshipAttributes, FriendshipAttributesMeta>[]
}

export const MissionsList = ({ onMissionClick, selectedMissionId, friends }: MissionListProps) => {

  const token = useLoaderData();
  const { data } = useUser()

  const { data: missionIds } = useCompassQuery(['missionIds'], getUserMissionIds, { staleTime: 0, refetchInterval: 0 })

  // Mission[refinementList][statut][0]=Incomplète&Mission[sortBy]=Mission:begin_at:asc
  const initialState = {
    "Mission": {
      "refinementList": {
        "statut": [
          "Incomplète"
        ]
      },
      "hierarchicalMenu": {
        "categorie.lvl0": ["Urgence et Secourisme", "DPS"],
        // "categorie.lvl1": ["Pré Inscription DPS"],
      },
      "sortBy": "Mission:begin_at:asc"
    }
  }

  const { searchClient } = instantMeiliSearch(
    import.meta.env.VITE_APP_MEILI_HOST,
    token as string,
  )

  const BG_CANCEL = 'repeating-linear-gradient(-45deg, transparent, transparent 20px, #ffd2cf87 20px, #ffd2cf87 40px)'
  const BG_FULL = 'repeating-linear-gradient(-45deg, transparent, transparent 20px, #55e20a29 20px,  #55e20a29 40px)'

  const colorForStatut = (statut: string | undefined) => {
    switch (statut) {
      case 'VALIDEE':
        return { bg: 'green.50', border: '1px solid', borderColor: 'green.600' }    
      case 'EN_ATTENTE':
        return { bg: 'green.50', border: '1px solid', borderColor: 'green.600' }    
      default:
        return { };
    }
  }

  const propsForState = ({ begin_at, statut, id }: { begin_at: string, statut: string, id: RecordId }) => {
    const props: any = {}

    if (isPast(parseISO(begin_at))) {
      props.opacity = '0.6'
    }

    if (statut === 'Complète') {
      props.backgroundImage = BG_FULL
    }

    if (statut === 'Annulée') {
      props.opacity = '0.8'
      props.backgroundImage = BG_CANCEL
    }

    if (selectedMissionId && id === selectedMissionId) {
      props.borderColor = 'gray.400'
      // props.bg = 'white'
    }

    return props
  }

  const Hit = ({ hit }) => {
    const user = missionIds?.[hit.id]

    const friendsCount = hit.friends[data?.attributes?.id || ''] && hit.friends[data?.attributes?.id || ''].length
    const hitfriends = friends.filter(e => (hit.friends[data?.attributes?.id || ''] ?? []).includes(e.attributes.friend_id))
    const statusProps = colorForStatut(user?.statut)
    const stateProps = propsForState(hit)

    const remaining = hit.total_missing > 0 ? `${hit.total_missing} places` : 'Complet'


    return (<Box
      as='a'
      borderRadius={3}
      p={3}
      flex={1}
      alignContent={'flex-start'}
      justifyContent={'flex-start'}
      alignItems={'stretch'}
      border='1px solid'
      borderColor={'white'}
      bg={'gray.50'}
      cursor='pointer'
      key={hit.id}
      onClick={() => onMissionClick(hit.id)}
      _hover={{
        // bg: 'white',
        borderColor: selectedMissionId !== hit.id ? 'gray.200' : 'gray.800'
      }}
      {...statusProps}
      {...stateProps}
    >
      <HStack alignItems={'stretch'} cursor='pointer'>
      <VStack flex={1} alignItems={'stretch'}>
        {missionIds?.[hit.id] && (<HStack>
          <Tag size={'sm'} colorScheme='green'><HStack>
            <TbMailCheck />
              <Text>{missionIds[hit.id].role}</Text>
            </HStack>
          </Tag>
        </HStack>)}
          <HStack flexWrap={'wrap'} alignContent = {'flex-start'} justifyContent = { 'space-between' }>
            <HStack flexWrap={'wrap'} alignContent={'flex-start'} justifyContent={'flex-start'}>
              <Text color="fg.muted" textTransform={'uppercase'} fontSize={'small'}>
                {formatDateShort(parseISO(hit.begin_at), {}, 'E d LLL HH:mm')}
              </Text>
              <Text color="fg.muted" textTransform={'uppercase'} fontSize={'small'}> - </Text>
              <Text color="fg.muted" textTransform={'uppercase'} fontSize={'small'}>
                {formatDistanceShort(parseISO(hit.begin_at))}
              </Text>
            <Text color="fg.muted" textTransform={'uppercase'} fontSize={'small'}> - </Text>
            <Text color="fg.muted" textTransform={'uppercase'} fontSize={'small'}>{truncate(hit.type_activite, 50)}</Text>
            {/* <Text color="fg.muted" textTransform={'uppercase'} fontSize={'small'}> - </Text>
            <Badge color="fg.muted" textTransform={'uppercase'} size={'small'}>{remaining}</Badge> */}
          </HStack>
          </HStack>
        <HStack flexWrap={'wrap'} alignContent={'flex-start'} justifyContent={'space-between'}>
        <HStack flexWrap={'wrap'}>
          {/* <Link noOfLines={1} fontSize={'lg'} fontFamily={poppinsFont} fontWeight={'bold'} target='_blank' rel='noopener' as={RouterLink} to={`https://pegass.croix-rouge.fr/planning-des-activites/activite/${hit.foreign_id}/`}><Highlight style={{ fontFamily: poppinsFont}} attribute="name" hit={hit} /></Link> */}
          <Text noOfLines={1} fontSize={'lg'} fontFamily={poppinsFont} fontWeight={'bold'}><Highlight style={{ fontFamily: poppinsFont}} attribute="name" hit={hit} /></Text>
          <HStack>
            {hit.tags.map(item => <Tag key={item} textTransform={'uppercase'} size='sm' fontSize={'xs'} colorScheme={TAGS_LABELS[item]?.color || 'black'}>{TAGS_LABELS[item]?.label || item}</Tag>)}
          </HStack>
          
          {/* <Tag lineHeight={0} textTransform={'uppercase'} size='sm' fontSize={'xs'} variant='outline' colorScheme={STATES_LABELS[hit.statut]?.color || 'black'}>{STATES_LABELS[hit.statut]?.label || hit.statut}</Tag> */}
          <Badge textTransform={'uppercase'} size={'small'}>{remaining}</Badge>
              {friendsCount > 0 && hitfriends.map(f => <Tag key={f.attributes.id} textTransform={'uppercase'} size='sm' fontSize={'xs'} variant='solid' colorScheme='blue'>{f.attributes.friend_full_name}</Tag>)}
          </HStack>
          {/* <HStack spacing={1}>
            <Text color='fg.subtle'><MdLocationCity /></Text>
            <Text noOfLines={1} color='fg.subtle'>{hit.structure_short_name}</Text>
          </HStack> */}
        </HStack>

      </VStack>
      </HStack>
    </Box>)
  }

  return (
    <Box>
      <InstantSearch
        indexName="Mission"
        searchClient={searchClient}
        routing
        initialUiState={initialState}
        future={{ preserveSharedStateOnUnmount: true }}
      // onStateChange={onStateChange}
      >
        <CategoryNav />
        <Stack spacing="5" p={3} pt={0} pr={0}>
          <HStack flexDirection={{ base: 'column', md: 'row' }} spacing={0} alignItems={'stretch'}>
            <MissionsListFilters />
            <Box flex={1}>
              <VStack spacing={3} alignContent={'flex-start'} alignItems={'stretch'} justifyContent={'stretch'}>
                <MissionsListToolbar />

                <Box p={3} pt={0} borderBottomWidth={'1px'} borderBottomColor={'bg.muted'} alignItems={'stretch'} justifyContent={'stretch'}>
                  <Hits hitComponent={Hit} />
                </Box>
                <HitsPagination />
              </VStack>
            </Box>
          </HStack>
        </Stack>
        </InstantSearch >
      </Box>
  );
}
