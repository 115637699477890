
import { PageWithTitle } from '@/components/PageWithTitle/PageWithTitle';
import { useParams } from 'react-router-dom';
import { MissionsList } from './MissionsList';
import { BoxSection } from '@/components/BoxSection/BoxSection';
import { useState } from 'react';
import MissionDetail from './MissionDetail';
import { Box, HStack } from '@chakra-ui/react';
import { useCompassQuery } from '@/hooks/query';
import { getUserFriendships, getUserFriendshipsKey } from '@/api/friendships';


export const AllMissionsPage = () => {

  const { orgId } = useParams()

  const [selectedMission, setSelectedMission] = useState<number | undefined>(undefined)

  const { data: friends } = useCompassQuery([getUserFriendshipsKey], getUserFriendships)


  return (
    <PageWithTitle
      title="Missions"
      actions={[]}
    >
      <BoxSection px={0} py={0}>
        <HStack flexDirection={{ base: 'column', md: 'row' }} spacing={0} alignItems={'stretch'}>
          <Box flex={'1'}>
            <MissionsList friends={friends?.data ?? []} onMissionClick={setSelectedMission} selectedMissionId={selectedMission}/>
          </Box>
          {selectedMission !== undefined && <Box flex={'0'} minW={'400px'}>
            <MissionDetail friends={friends?.data ?? []} missionId={selectedMission} onClose={() => setSelectedMission(undefined)} />
          </Box>}
        </HStack>
        </BoxSection>
    </PageWithTitle>
  );
}

export default AllMissionsPage;