import type { CreateFriendshipAttributes, FriendshipAttributes, FriendshipAttributesMeta } from "@/interfaces/friendship";
import { ROUTES } from "../constants/routes";
import { type ApiCollectionResponse, type ApiResourceResponse, defaultAuthHeaders, formatSort, handleApiResponse } from "@/utils/api";
import type { RecordId } from "@/interfaces/id";
import type { SortingState } from "@tanstack/react-table";
import QueryString from "qs";


export const getUserFriendshipsKey = "UserFriendships"
export const getFriendshipKey = "Friendship"


export async function getUserFriendships(): Promise<ApiCollectionResponse<FriendshipAttributes, unknown, FriendshipAttributesMeta>> {
  const response = await fetch(
    ROUTES.friendships.list(), { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}

export async function getFriendship(friendshipId: RecordId): Promise<ApiResourceResponse<FriendshipAttributes, unknown, FriendshipAttributesMeta>> {
  const response = await fetch(
    ROUTES.friendships.get(friendshipId), { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}

export function getFriendshipQuery(friendshipId: RecordId) {
  return {
    queryKey: [getFriendshipKey, friendshipId],
    queryFn: () => getFriendship(friendshipId)
  }
}

export async function createFriendship(friendship: CreateFriendshipAttributes): Promise<ApiResourceResponse<FriendshipAttributes, unknown, FriendshipAttributesMeta>> {
  const response = await fetch(
    ROUTES.friendships.create(), {
      headers: defaultAuthHeaders(),
      method: 'POST',
      body: JSON.stringify({ friendship }),
    }
  )
  return await handleApiResponse(response);
}

export async function deleteFriendship(friendshipId: RecordId): Promise<ApiResourceResponse<FriendshipAttributes, unknown, FriendshipAttributesMeta>> {
  const response = await fetch(
    ROUTES.friendships.get(`${friendshipId}`), {
      headers: defaultAuthHeaders(),
      method: 'DELETE',
    }
  )
  return await handleApiResponse(response);
}
