

export interface TagLabel {
  label: string
  color: string
}


export const TAGS_LABELS: { [key: string]: TagLabel } = {
  "Maraude": {
    label: '🚚 Maraude',
    color: 'blue',
  },
  "Tout-Chemin": {
    label: '⛰️ Tout-Chemin',
    color: 'green',
  },
  "PAPS": {
    label: '✌️ PAPS',
    color: 'purple',
  },
  "Sport": {
    label: '🏟️ Sport',
    color: 'yellow',
  },
  "Concert": {
    label: '🧑‍🎤 Concert',
    color: 'teal',
  },
  "Grande Envergure": {
    label: '🎡 Grand',
    color: 'cyan',
  },
  "Entrainement": {
    label: '🧑‍🏫 Entrainement',
    color: 'pink',
  },
  "Renfort": {
    label: '⛺️ Renfort',
    color: 'pink',
  },
  "Festival": {
    label: '🎪 Festival',
    color: 'orange',
  },
}

