
import { Link as RouterLink } from 'react-router-dom';
import { Avatar, AvatarBadge, Box, Button, Heading, HStack, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, Text, Tooltip, VStack } from '@chakra-ui/react';
import { MdCalendarMonth, MdClose, MdLocationCity } from 'react-icons/md';
import { useCompassQuery } from '@/hooks/query';
import { getMission, getMissionKey } from '@/api/mission';
import Loader from '@/components/Loader/Loader';
import { TbMap, TbPlus } from 'react-icons/tb';
import { poppinsFont } from '@/utils/string';
import { PegassLogo } from '@/components/PegassLogo/PegassLogo';
import { parseISO } from 'date-fns';
import { formatDateShort } from '@/utils/datesFormat';
import { MissionUserWithUser } from '@/interfaces/missionUser';
import { QueryClient, useMutation } from '@tanstack/react-query';
import { createFriendship, deleteFriendship, getUserFriendshipsKey } from '@/api/friendships';
import { CreateFriendshipAttributes, FriendshipAttributes, FriendshipAttributesMeta } from '@/interfaces/friendship';
import { ApiResource } from '@/utils/api';
import { FaHeart } from 'react-icons/fa';
import { RecordId } from '@/interfaces/id';

const queryClient = new QueryClient()

interface MissionDetailProps {
  missionId: number | undefined,
  onClose: () => void,
  friends: ApiResource<FriendshipAttributes, FriendshipAttributesMeta>[]
}

interface UserPopupProps {
  user: MissionUserWithUser,
  friendshipId: RecordId | undefined
}


const UserPopup = ({
  user,
  friendshipId
}: UserPopupProps) => {

  const { mutateAsync: addFriend, isPending: addFriendPending, isSuccess: addFriendSuccess } = useMutation(
    {
      mutationFn: (data: CreateFriendshipAttributes) => createFriendship(data)
    }
  )

  const { mutateAsync: removeFriend, isPending: removeFriendPending, isSuccess: removeFriendSuccess } = useMutation(
    {
      mutationFn: (friendshipId: RecordId) => deleteFriendship(friendshipId),
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({ queryKey: [getUserFriendshipsKey] })
      },
    }
  )

  const onAddFriend = async () => {
    await addFriend({ friend_id: user.user_id })
  }

  const onRemoveFriend = async () => {
    if (friendshipId) {
      await removeFriend(friendshipId)
    }
  }

  return (
    <Popover isLazy key={user.id} closeOnBlur closeOnEsc trigger='hover'>
      <PopoverTrigger>
        <Avatar cursor={'pointer'} size={'sm'} name={user.user?.full_name}>
          {friendshipId && <AvatarBadge bg='white' boxSize='1.25em'><FaHeart color='red' /></AvatarBadge>}
          {/* {user.statut === "EN_ATTENTE" ? (
            <AvatarBadge boxSize='1.25em' borderColor='papayawhip' bg='tomato' />) : (
            <AvatarBadge boxSize='1.25em' bg='green.500' />)
          } */}
        </Avatar>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>{user.user?.full_name}</PopoverHeader>
        <PopoverBody>
          <VStack flexWrap={'wrap'} alignItems={'flex-start'} alignContent={'flex-start'} justifyContent={'flex-start'}>
            <Text>Status: {user.statut?.replace('_', ' ')?.toLocaleLowerCase()}</Text>
          </VStack>
        </PopoverBody>
        <PopoverFooter>
          <HStack flexWrap={'wrap'} alignItems={'center'} alignContent={'flex-start'} justifyContent={'space-between'}>
            {friendshipId ? (
              <Button onClick={onRemoveFriend} isLoading={removeFriendPending} size={'sm'} colorScheme='blue'>{removeFriendSuccess ? 'Supprimé' : 'Ne plus suivre'}</Button>
            ) : (
              <Button onClick={onAddFriend} isLoading={addFriendPending} size={'sm'} colorScheme='blue'>{addFriendSuccess ? 'Suivi' : 'Suivre'}</Button>
            )}
            {/* <Button size={'sm'} colorScheme='blue'>Suivre</Button> */}
          </HStack>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  )
}

export const MissionDetail = ({ missionId, onClose, friends }: MissionDetailProps) => {

  const { isLoading, data, isSuccess } = useCompassQuery([getMissionKey, missionId], () => getMission(missionId || ''))

  const mission = data?.data?.attributes
  const structure = data?.data?.meta?.structure
  return (
    <Box position={'sticky'} maxH={'100vh'} overflowY={'auto'} top={'0px'} borderLeft={'1px solid'} borderLeftColor={'bg.muted'} height={'100%'}>
      <HStack alignItems={'center'} px={6} py={3} alignContent={'flex-start'} justifyContent={'space-between'} borderBottom={'1px solid'} borderBottomColor={'bg.muted'}>
        <Heading size={'xs'}>{data?.data?.attributes?.name}</Heading>
        <Button variant={'ghost'} onClick={onClose}><MdClose /></Button>
      </HStack>
      {!isSuccess && <Loader />}
      {isSuccess && mission && (<Box p={6} >
        <VStack flexWrap={'wrap'} alignItems={'flex-start'} spacing={6} alignContent={'flex-start'} justifyContent={'flex-start'}>
          <Button w={'100%'} leftIcon={<PegassLogo h={5} />} variant={'primary-on-accent'} colorScheme='red' fontSize={'lg'} fontFamily={poppinsFont} fontWeight={'bold'} target='_blank' rel='noopener' as={RouterLink} to={`https://pegass.croix-rouge.fr/planning-des-activites/activite/${mission.foreign_id}/`}>Inscription</Button>
          <VStack flexWrap={'wrap'} alignItems={'flex-start'} alignContent={'flex-start'} justifyContent={'flex-start'}>
            <HStack spacing={1}>
              <Text color='fg.subtle'><MdCalendarMonth /></Text>
              <Text color="fg.muted" textTransform={'uppercase'} fontSize={'small'}>
                Horaires
              </Text>
            </HStack>
            <Text noOfLines={1} textTransform={'capitalize'}><b>De :</b>{' '}{formatDateShort(parseISO(mission.begin_at), {}, 'EEEE d LLL HH:mm')}</Text>
            <Text noOfLines={1} textTransform={'capitalize'}><b>À :</b>{' '}{formatDateShort(parseISO(mission.end_at), {}, 'EEEE d LLL HH:mm')}</Text>
            {/* <Text noOfLines={1} textTransform={'capitalize'}><b>Durée</b>{' '}{formatDistance(parseISO(mission.begin_at), parseISO(mission.end_at), { locale: })}</Text> */}
          </VStack>
          <VStack flexWrap={'wrap'} alignItems={'flex-start'} alignContent={'flex-start'} justifyContent={'flex-start'}>
            <HStack spacing={1}>
              <Text color='fg.subtle'><MdLocationCity /></Text>
              <Text color="fg.muted" textTransform={'uppercase'} fontSize={'small'}>
                Structure organisatrice
              </Text>
            </HStack>
            <Text noOfLines={1}>{structure?.name}</Text>
          </VStack>
          <VStack flexWrap={'wrap'} alignItems={'flex-start'} alignContent={'flex-start'} justifyContent={'flex-start'}>
            <HStack spacing={1}>
              <Text color='fg.subtle'><TbMap /></Text>
              <Text color="fg.muted" textTransform={'uppercase'} fontSize={'small'}>
                Adresse
              </Text>
            </HStack>
            <Text>{mission.address}</Text>
          </VStack>

          <Heading size={'2xs'}>Résumé</Heading>
          <VStack flexWrap={'wrap'} alignItems={'flex-start'} alignContent={'flex-start'} justifyContent={'flex-start'}>
            <HStack flexWrap={'wrap'} alignContent={'flex-start'} justifyContent={'flex-start'}>
              <Text color="fg.muted">
                {mission.content || 'Aucune description'}
              </Text>
            </HStack>
          </VStack>

          <Heading size={'2xs'}>Inscriptions</Heading>

          {mission.mission_requirements.sort((a, b) => a.role.localeCompare(b.role)).map(mr => {

            const users = mission.mission_users.filter(mu => mu.fullrole === mr.role)

            return (<VStack key={mr.id} flexWrap={'wrap'} alignItems={'flex-start'} alignContent={'flex-start'} justifyContent={'flex-start'}>
              <HStack spacing={1}>
                <Text color="fg.muted" textTransform={'uppercase'} fontSize={'small'}>{(mr.total || 0) - (mr.missing || 0)}/{(mr.total || 0)} {mr.role}</Text>
              </HStack>

              <HStack spacing={1} flexWrap={'wrap'}>
                {users.map((elt) => (
                  <UserPopup friendshipId={friends.find(e => e.attributes.friend_id === elt.user_id)?.id} user={elt} key={elt.id} />
                ))}
                {[...Array(mr.missing > 0 ? mr.missing : 0)].map((elt) => (
                  <Tooltip key={elt} label={'Place disponible'}>
                    <Avatar bg='gray.100' color='gray.500' icon={<TbPlus  fontSize='1.1rem' />} size={'sm'}>
                    </Avatar>
                  </Tooltip>
                ))}
              </HStack>

            </VStack>)
          })}


        </VStack>

      </Box>)}
    </Box>
  );
}

export default MissionDetail;